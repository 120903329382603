"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validators = exports.telNummerCleaning = exports.default = void 0;

var isUserNameValid = function isUserNameValid(name) {
  var minNameLength = 3; // eslint-disable-next-line no-useless-escape

  var format = /[`0-9!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
  var retVal = true;

  if (!name || 0 === name.length || name[0] === ' ') {
    retVal = false;
  } else {
    retVal = name.length < minNameLength ? false : format.test(name) ? false : true;
  }

  return retVal;
};

var isNewPasswordValid = function isNewPasswordValid(pasw) {
  var minNameLength = 6;
  var retVal = true;

  if (!pasw || 0 === pasw.length || pasw[0] === ' ') {
    retVal = false;
  } else {
    retVal = pasw.length < minNameLength ? false : true;
  }

  return retVal;
};

var allEqual = function allEqual(val) {
  var checkEqual = function checkEqual(arr, len) {
    return arr.length > len ? arr.every(function (v) {
      return v === arr[0];
    }) : true;
  };

  return checkEqual(val.replace('+', '').split(''), 7);
};

var emailRules = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var phoneRules = /^(?:00|\+|\+\+)(99[1-8]|97[012345679]|96[0-8]|9[8543210]|88[01236]|87[08]|85[02356]|80[08]|8[6421]|7|69[012]|68[012356789]|67[023456789]|6[6543210]|5[09]\d|5[1-8]|42[013]|4[987654310]|38[01235679]|3[57]\d|3[9643210]|29[01789]|25[0-8]|21[12368]|2[6432]\d|2[70]|1)(?:0){0,1}([1-9]\d{4,14})$/;
var postcRules = /^[0-9]{4,8}$/;

var telNummerCleaning = function telNummerCleaning(val) {
  return (// eslint-disable-next-line eqeqeq
    (val[0] == '+' ? '+' + val.replace(/\D/g, '') : val.replace(/\D/g, '')).replace(phoneRules, '+$1$2')
  );
};

exports.telNummerCleaning = telNummerCleaning;
var validators = {
  phone: function phone(input) {
    return !allEqual(input) ? phoneRules.test(input) : false;
  },
  email: function email(input) {
    return emailRules.test(input);
  },
  name: function name(input) {
    return isUserNameValid(input);
  },
  password: function password(input) {
    return isNewPasswordValid(input);
  },
  postcode: function postcode(input) {
    return postcRules.test(input);
  }
}; // eslint-disable-next-line import/no-anonymous-default-export

exports.validators = validators;

var _default = function _default(type) {
  return function (input) {
    return validators[type] ? validators[type](input) : true;
  };
};

exports.default = _default;